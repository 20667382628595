import { Inject, Injectable, isDevMode, NgZone, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StoreDetail } from '../../models/store-details.model';
import { PanelService } from '../api/panel.service';
import { StateService } from '../storage/state.service';
import { ForbiddenRoutes } from '../../enum/forbidden-route';
import { isPlatformBrowser, isPlatformServer, PlatformLocation } from '@angular/common';
import { Observable } from 'rxjs';
import { ApiKeySelector } from 'store.env';
import { TitleService } from '../title.service';
import { LocalStorageService } from '../storage/local-storage.service';
@Injectable({
  providedIn: 'root',
})
export class StoreDetailService {
  private storeDetail: StoreDetail;
  platformId: Object;
  public constructor(
    private panelService: PanelService,
    private titleService: TitleService,
    private router: Router,
    private stateService: StateService,
    private platformLocation: PlatformLocation,
    private localStorage: LocalStorageService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.platformId = platformId;
  }

  private resolveInfo(): Promise<any> {
    let url: Observable<StoreDetail>;
    let storeUrl;

    if (isPlatformBrowser(this.platformId)) {
      storeUrl = this.generateStoreRoute();
      if(!this.localStorage.getItem('store-key')){
        url = this.panelService.getStoreKey({ url: storeUrl });
      }
      else{
        url = this.panelService.getStoreDetail();
      }
    } else {
      // const storeApiKey = process.env[ApiKeySelector];
      url = this.panelService.getStoreDetail();
    }
    return new Promise((resolve, reject) => {
      url.subscribe(
        (data) => {
          this.storeDetail = data;
          if(!this.localStorage.getItem('store-key')){
            this.localStorage.setItem('store-key',this.storeDetail.client_id)
          }
          if(!this.localStorage.getItem('userCurrency')) {
            this.localStorage.setItem('userCurrency', this.storeDetail.currency_detail);
          }
          this.titleService.updateTitle(data.name);
          resolve('');
        },
        (error) => {
          this.localStorage.deleteItem('store-key');
          this.router.navigate(['/access-denied']);
          reject();
        }
      );
    });
  }

  get permissionInit() {
    return this.storeDetail;
  }

  public updateInfo() {
    this.resolveInfo();
  }

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    return this.resolveInfo();
  }

  generateStoreRoute(): String {
    let subPath;
    let isLocalRoute;
    let windowLocation;

    if (isDevMode()) {
      return 'http://brokerplus.mana-card.com';
    }

    subPath = window.location.pathname.split('/')[1];
    windowLocation = window.location.origin;
    isLocalRoute = ForbiddenRoutes.find((el) => el == subPath);
    return isLocalRoute || !subPath
      ? windowLocation
      : `${windowLocation}/${subPath}`;

    // console.log('__________Response___________', res)
  }
}
