import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PanelService } from '../../services/api/panel.service';
import { ProductSearch } from '../../models/products.model';
import { DialogPosition, MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { StoreRegionService } from '../../services/store-region.service';

@Component({
  selector: 'shared-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {

  searchState = {
    IDLE: 'idle',
    SEARCHING: 'searching',
    NAVIGATING: 'navigating'
  }

  @Input() isMobile?: boolean = false;
  @ViewChild('searchResultDialog') searchResultDialog: any;
  @ViewChild('searchForm') searchForm: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  searchField = new FormControl('');
  searchResult: ProductSearch[];
  dialogRef: MatDialogRef<any>;
  state = this.searchState.IDLE;  
  readonly resultLimit = 3;

  constructor(
    private panelService: PanelService,
    private dialog: MatDialog,
    private router: Router,
    private googleTagManagerService: GoogleTagManagerService,
    private regionService: StoreRegionService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if(!this.searchForm.nativeElement || this.searchForm.nativeElement.offsetHeight === 0) {
      this.closeDialog();
      return;
    }
    this.updateDialogPosition()
  }

  ngOnInit(): void {
    this.initSearchListener();
  }

  private initSearchListener() {
    this.searchField.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      const keyword = value.trim();      
      if(keyword.length >= 2) {     
        this.performSearch(keyword)
      } else {
        this.searchResult = undefined;
        this.closeDialog()
      }
    })
  }

  private performSearch(query: string,) {
    this.state = this.searchState.SEARCHING;
    this.sendSearchEvent(query);
    this.panelService.searchProductByName(query, this.regionService.selectedRegion.id).subscribe((result: any) => {
      this.searchResult = result.results.map(item => ({
        ...item,
        url: this.getUrl(item.id, item.name)
      }));
      if(this.state === this.searchState.NAVIGATING) {
        this.resetSearch();
      } else {
        this.openSearchResult();
      }
    })
  }

  private getUrl(id: number, name: string) {
    let title = name.replace(/[^a-zA-Z0-9]+/g, '-');
    return `/product/${id}/${title}`;
  
  }

  private openSearchResult() {
    this.state = this.searchState.IDLE;
    this.closeDialog();
    this.dialogRef = this.dialog.open(this.searchResultDialog, {
      autoFocus: false,
      restoreFocus: false,      
      position: this.dialogPositions,
      panelClass: 'dialog-sm-padding',
      minWidth: 400,
    });
    this.dialogRef.afterOpened().subscribe(() => this.searchInput.nativeElement.focus() );
  }

  private sendSearchEvent(term: string) {
    this.googleTagManagerService.sendAnalytics('search', {term})
  }

  private closeDialog() {
    if(this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = undefined;
  }

  private updateDialogPosition() {
    if(!this.dialogRef) return;
    this.dialogRef.updatePosition(this.dialogPositions);
  }

  onFocusSearchField() {
    if(this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) return;

    if(this.searchResult && this.searchResult.length > 0) {
      this.openSearchResult();
    }
  }

  private get dialogPositions(): DialogPosition {
    const { offsetTop, offsetHeight, offsetLeft } = this.searchForm.nativeElement;
    const suffix = 'px';
    const marginTop = 10;
    
    return {
      top: offsetTop + offsetHeight + marginTop + suffix,
      left: offsetLeft + suffix
    }
  }

  onSearchSubmit() {
    this.state = this.searchState.NAVIGATING;
    this.router.navigate(['/category'], {
      queryParams: { search: this.searchField.value },
      queryParamsHandling: 'merge'
    }).then(res => {
      this.resetSearch();
    })
  }

  onNavigate(item: ProductSearch) {
    this.sendProductEvent(item);
    this.resetSearch();
  }

  sendProductEvent(product: ProductSearch) {
    const emptyTxt = '---';
    let analyticData = {
      currency: emptyTxt,
      value: product.id,
      id: product.id,
      name: product.name,
      discount_percent: emptyTxt,
      cashback_percent: emptyTxt,
      source: 'search',
      min_price: emptyTxt,
      max_price: emptyTxt
    }    

    this.googleTagManagerService.sendAnalytics('view_product', { ...analyticData })
  }

  private resetSearch() {
    this.closeDialog();
    this.searchField.reset('',{emitEvent: true});
  }

}
